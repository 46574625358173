import React from 'react';
import { useTranslation } from 'react-i18next';
import { paragraphBoldStyles } from '../../../../styles';
import { Tile, TileHeader, TileBody, InfoItem } from '../../../../components';
import {
  containerStyles,
  twoColumsTileStyles,
  infoItemStyles,
  leftColumnStyles,
  removeLastBottomBorder,
  rightColumnStyles,
  topPadding,
  tileStyles,
} from './styles';
import { formatPumpInput } from './utils';
import {
  OperatingInfo,
  ProportionalConcentrationTarget,
  ProportionalLastHour,
  TotalDosedChemical,
  TotalVolumeProcessed,
  ChemicalContainer,
  ProportionalDosingData,
  AnalogRuleInfoItemHigherCurrent,
  AnalogRuleInfoItemLower,
} from './components';
import { MoreDataTabAnalogProps } from './types';

const MoreDataTabAnalog: React.FC<MoreDataTabAnalogProps> = ({
  chemicalContainer,
  totalDosedChemical,
  motorOperatingHours,
  totalOperatingHours,
  dosedChecmicalLastHour,
  dosedChecmicalLastHourUnit,
  dosedProcessVolLastHour,
  actualProportionalConcentration,
  targetProportionalConcentration,
  totalVolumeProcessLiquid,
  pumpHigherInput,
  pumpLowerInput,
  settingsHigherInput,
  settingsLowerInput,
  proportionalRuleValidation,
  controlMode,
}) => {
  const { t } = useTranslation();
  return (
    <div css={containerStyles}>
      <div css={leftColumnStyles}>
        <ProportionalDosingData>
          <div>
            <ProportionalLastHour
              dosedChecmicalLastHour={dosedChecmicalLastHour}
              dosedChecmicalLastHourUnit={dosedChecmicalLastHourUnit}
              dosedProcessVolLastHour={dosedProcessVolLastHour}
              actualProportionalConcentration={actualProportionalConcentration}
            />
          </div>
          <div>
            <ProportionalConcentrationTarget
              targetProportionalConcentration={targetProportionalConcentration}
              proportionalRuleValidation={proportionalRuleValidation}
            />
            <div>
              <AnalogRuleInfoItemLower
                label={t('WP.MORE.DATA_PUMP_LOWER_MA_FLOW')}
                pumpInput={pumpLowerInput}
                proportionalRuleValidation={proportionalRuleValidation}
                dosingVolumeDecimals={4}
                controlMode={controlMode}
              />
              <InfoItem
                css={infoItemStyles}
                label={t('WP.MORE.DATA_SENSOR_LOWER_MA_FLOW')}
                value={formatPumpInput(settingsLowerInput, 2, 3)}
                labelMultiline
              />
              <AnalogRuleInfoItemHigherCurrent
                label={t('WP.MORE.DATA_PUMP_HIGHER_MA_FLOW')}
                pumpInput={pumpHigherInput}
                proportionalRuleValidation={proportionalRuleValidation}
                dosingVolumeDecimals={4}
              />
              <InfoItem
                css={infoItemStyles}
                label={t('WP.MORE.DATA_SENSOR_HIGHER_MA_FLOW')}
                value={formatPumpInput(settingsHigherInput, 2, 3)}
                labelMultiline
              />
            </div>
          </div>
        </ProportionalDosingData>
        <ChemicalContainer chemicalContainer={chemicalContainer} />
      </div>
      <div css={rightColumnStyles}>
        <Tile
          styles={tileStyles}
          Header={<TileHeader text={t('WP.MORE.DATA.DOSING_COUNTER_TILE')} />}
          Body={
            <TileBody css={[topPadding, twoColumsTileStyles]}>
              <div css={removeLastBottomBorder}>
                <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.VOLUME')}</p>
                <TotalDosedChemical totalDosedChemical={totalDosedChemical} />
                <TotalVolumeProcessed totalVolumeProcessLiquid={totalVolumeProcessLiquid} />
              </div>
              <OperatingInfo motorOperatingHours={motorOperatingHours} totalOperatingHours={totalOperatingHours} />
            </TileBody>
          }
        />
      </div>
    </div>
  );
};

export default MoreDataTabAnalog;
