import { RuleViolation } from '../../types';
import {
  DDACControlMode,
  RemoteControlValues,
} from '../hooks/__generated__/useSendRemoteControlCommandsMutation.graphql';
import { FormValues } from '../types';

// Formats the given form values by converting specific fields to numbers and returning the rest as is.
export const formatFormValues = (values: FormValues): RemoteControlValues => {
  const {
    mlPerPulse,
    manualDosingFlowValue,
    maxCapacity,
    analogDosingFlowHigher,
    analogDosingFlowLower,
    analogInputHigher,
    analogInputLower,
    ...rest
  } = values;

  const stringValues = {
    mlPerPulse,
    manualDosingFlowValue,
    maxCapacity,
    analogDosingFlowHigher,
    analogDosingFlowLower,
    analogInputHigher,
    analogInputLower,
  };

  // Fields with string values are converted to numbers
  const stringToNumberValues = Object.fromEntries(
    Object.entries(stringValues)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => [key, Number(value)]),
  );
  return {
    ...rest,
    ...stringToNumberValues,
  };
};

// Filter out analog values (default setting)
export const withoutAnalogValues = (
  formValues: FormValues,
): Omit<FormValues, 'analogInputHigher' | 'analogInputLower' | 'analogDosingFlowLower'> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { analogInputHigher, analogInputLower, analogDosingFlowLower, ...rest } = formValues;
  return rest;
};

export const proportionalRuleValidationExtender = (
  ruleViolation: RuleViolation | null | undefined,
  controlMode?: DDACControlMode | null,
) => {
  const { remedy, ruleViolations } = ruleViolation || {};
  if (!ruleViolations || ruleViolations.length === 0) return ruleViolation;
  const extendedRules = ruleViolations.map((rule) => {
    if (rule === 'AnalogOperatingModeFlowSensorMismatch' && controlMode !== null) {
      // we only get one rule violation from the server. We need to convert this to two rules that we can use in our toolTip component
      if (controlMode === 'ANALOG_4_20') return 'AnalogOperatingModeFlowSensorMismatch420';
      if (controlMode === 'ANALOG_0_20') return 'AnalogOperatingModeFlowSensorMismatch020';
    }
    return rule;
  });
  return { ruleViolations: extendedRules, remedy };
};
