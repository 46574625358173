import React from 'react';
import { Field, useField, FieldHookConfig } from 'formik';
import { useTheme } from '@emotion/react';
import {
  labelStyles,
  fieldStyles,
  errorStyles,
  containerStyles,
  dropdownContainer,
  dropdownField,
  prefixStyles,
  dropdownContainerWrapperStyles,
} from './styles';

interface DropDownSelectProps {
  label: string | JSX.Element;
  className?: string;
  alignment?: 'center' | 'start' | 'end';
  disabled?: boolean;
  onSelectValue?: (fieldName: string, value: string) => void;
  testId?: string;
  Prefix?: JSX.Element;
}

const DropDownSelect: React.FC<FieldHookConfig<string> & DropDownSelectProps> = (props) => {
  const [field, meta] = useField(props);
  const theme = useTheme();
  const { name, label, className, children, alignment, onSelectValue, disabled = false, testId, Prefix } = props;
  return (
    <div css={containerStyles(alignment)} className={className} data-testid={testId}>
      <label htmlFor={name} css={labelStyles(disabled, theme)}>
        {label}
      </label>
      <div css={dropdownContainerWrapperStyles}>
        {Prefix && <div css={prefixStyles}>{Prefix}</div>}
        <div css={[dropdownContainer]}>
          <Field
            name={name}
            data-testid={`${testId}-select`}
            as="select"
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (onSelectValue) onSelectValue(e.target.name, e.target.value);
              field.onChange(e);
            }}
            css={[dropdownField, fieldStyles(disabled, theme)]}
          >
            {children}
          </Field>
        </div>
      </div>
      {meta.touched && meta.error ? <div css={errorStyles}>{meta.error}</div> : null}
    </div>
  );
};

export default DropDownSelect;
